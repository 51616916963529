.top-bar {
  background: #000;

  &_preview {
    $light-stripe: #993;
    $dark-stripe: #330;
    background-image: linear-gradient(135deg, $dark-stripe 21.43%, $light-stripe 21.43%, $light-stripe 50%, $dark-stripe 50%, $dark-stripe 71.43%, $light-stripe 71.43%, $light-stripe 100%);
    background-size: 29.70px 29.70px;
  }

  &__desktop {
    .screen_desktop & {
      width: 1090px;
      height: $top-bar-height-desktop;
      margin: 0 auto;
      padding-top: 2px;
    }

    .screen_mobile & {
      height: $top-bar-height-mobile;
    }
  }

  &__logo {
    padding: 0 20px 0 0;

    .screen_mobile & {
      zoom: 50%;
      padding: 0 10px 0 30px;
    }
  }

  &__all-events {
    line-height: 48px;
    white-space: nowrap;
    padding: 2px 10px 0;
    color: #fff;
    font-size: 17px;
    text-align: center;
  }

  &__profile {
    width: 47px;
    height: 47px;
    margin-left: 20px;
    background-size: cover;
    border-radius: 6px;
    background: #ddd;
  }

  &__title {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;

    .screen_desktop & {
      max-height: $top-bar-height-desktop;
    }
    .screen_mobile & {
      max-height: $top-bar-height-mobile;
    }
  }

  &__btn {
    color: $primary;
    border: none;
    background: transparent;

    &_menu {
      background: url(/img/layout/hamburger.svg) 50% 50% no-repeat;
      width: 44px;
      height: 44px;
    }

    &_new {
      color: $red;
    }

    &_chats {
      background: url(/img/layout/chats.svg) 50% 12px no-repeat;
      background-size: 27px auto;
      position: relative;
      width: 60px;
      height: 47px;
      margin-left: 13px;
    }
  }

  &__chats-count {
    $border: 1px;
    position: absolute;
    top: 6px;
    right: -2px;
    display: block;
    background: #ff5530;
    color: #fff;
    border: solid $border #242424;
    border-radius: 50%;
    width: 24px + $border * 2;
    font-size: 15px;
    line-height: 24px;
  }
}
