.news {
  font-size: 12px;

  &__title {
    font-size: inherit;
    font-weight: bold;
  }

  &__news-article {
    padding-bottom: 3px;
    border-bottom: dotted 1px $gray-500;
  }
}
