.layout {
  min-height: 100vh;
  @extend .d-flex;
  @extend .flex-column;

  &__profile-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
  }

  &__body {
    @extend .flex-fill;
  }

  &__content-wrap {
    .screen_desktop & {
      display: flex;
      width: 830px;
      margin: 0 auto;
    }

    &_wide {
      .screen_desktop & {
        width: 1200px;
      }
    }
  }

  &__news {
    width: 200px;
    padding: $grid-gutter-width / 2;

    .screen_mobile {
      display: none;
    }
  }

  &__content {
    flex: 1;
    padding-right: $grid-gutter-width / 2;
    padding-left: $grid-gutter-width / 2;
  }

  &__footer {
    background: #242424;
    color: #f3f3f3;
    height: 30px;
  }

  &__overlay {
    z-index: $zindex-modal-backdrop;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__loader {
    z-index: $zindex-loader;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
