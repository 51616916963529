.logins-history {

  &__table {
    font-size: 12px;
  }

  &__misc {
    width: 50px;
    height: 20px;
    overflow: auto;
    white-space: nowrap;
  }
}
