/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Moved to ../vars-and-mixins.scss
//@import "../../../../node_modules/bootstrap/scss/functions";
//@import "../../../../node_modules/bootstrap/scss/variables";
//@import "../../../../node_modules/bootstrap/scss/mixins";

// Collect Bootstrap
@import "../../../../node_modules/bootstrap/scss/root"; // CSS vars (not SCSS)
@import "../../../../node_modules/bootstrap/scss/reboot"; // Normalize
@import "../../../../node_modules/bootstrap/scss/type"; // Typography
//@import "../../../../node_modules/bootstrap/scss/images";
//@import "../../../../node_modules/bootstrap/scss/code";
@import "../../../../node_modules/bootstrap/scss/grid";
@import "../../../../node_modules/bootstrap/scss/tables";
@import "../../../../node_modules/bootstrap/scss/forms";
@import "../../../../node_modules/bootstrap/scss/buttons";
//@import "../../../../node_modules/bootstrap/scss/transitions";
//@import "../../../../node_modules/bootstrap/scss/dropdown";
//@import "../../../../node_modules/bootstrap/scss/button-group";
@import "../../../../node_modules/bootstrap/scss/input-group";
@import "../../../../node_modules/bootstrap/scss/custom-forms";
@import "../../../../node_modules/bootstrap/scss/nav";
//@import "../../../../node_modules/bootstrap/scss/navbar";
@import "../../../../node_modules/bootstrap/scss/card";
@import "../../../../node_modules/bootstrap/scss/breadcrumb";
.breadcrumb { margin-top: 15px } // TODO: Place better than this
//@import "../../../../node_modules/bootstrap/scss/pagination";
@import "../../../../node_modules/bootstrap/scss/badge";
//@import "../../../../node_modules/bootstrap/scss/jumbotron";
@import "../../../../node_modules/bootstrap/scss/alert";
@import "../../../../node_modules/bootstrap/scss/progress";
//@import "../../../../node_modules/bootstrap/scss/media";
//@import "../../../../node_modules/bootstrap/scss/list-group";
@import "../../../../node_modules/bootstrap/scss/close";
//@import "../../../../node_modules/bootstrap/scss/toasts";
@import "../../../../node_modules/bootstrap/scss/modal";
//@import "../../../../node_modules/bootstrap/scss/tooltip";
//@import "../../../../node_modules/bootstrap/scss/popover";
//@import "../../../../node_modules/bootstrap/scss/carousel";
//@import "../../../../node_modules/bootstrap/scss/spinners";
@import "../../../../node_modules/bootstrap/scss/utilities";
@import "../../../../node_modules/bootstrap/scss/print";
