.sales {
  &__office {
    cursor: pointer;

    &_selected {
      cursor: default;
      background: #ccc !important; // Blocking hover
    }
  }
}
