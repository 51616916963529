$top-bar-height-mobile: 44px;
$top-bar-height-desktop: 66px;
$event-menu-height-mobile: 57px;

// These classes will be automatically added to some root tag. Use to cascade
.screen_mobile {}
.screen_desktop {}

@import "views/MainLayoutView";
@import "views/HeaderWidgetView";
@import "views/FooterWidgetView";
@import "views/HeaderWidgetView";
// ### GENERATED IMPORTS GO HERE ###
