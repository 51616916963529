.search-device {

  &__dropdown-wrapper {
    position: relative;
  }

  &__dropdown {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    background: white;
    border: solid 1px #ccc;
    border-top-width: 0;
    border-radius: 0 0 5px 5px;
    padding: 5px 3px;
  }

  &__item {
    height: 50px;
    cursor: pointer;
    border: dotted 1px transparent;

    &_selected {
      border-color: black;
    }
  }

  &__pic {
    display: inline-block;
    width: 46px;
    height: 46px;
    margin: 0 10px 0 1px;
    background: no-repeat 50% 50%;
    background-size: contain;
  }
}
